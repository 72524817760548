import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            user: {},
            error: {
                email: null,
                password: null
            },
            errorApi: null,
            redirect: false,
            isLoading: false,
        }
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(token) {
            this.props.history.goBack();
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'email':
                this.setState({email: value});
                break;
            case 'password':
                this.setState({password: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {email, password, error} = this.state;

        if(email === '') {
            error.email = 'Veuillez renseigner votre email';
        } else {
            error.email = null;
        }
        if(password === '') {
            error.password = 'Veuillez renseigner votre mot de passe';
        } else {
            error.password = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({error, isLoading: false});
        } else {            
            const {email, password} = this.state;
            this.sendFormData(email, password);
        }
    }

    sendFormData = (email, password) => {
        let formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        Axios.post(`${url}/api/office/login`, formData)
            .then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {                        
                        this.setState({isLoading: false, errorApi: res.data.message});
                    } else {
                        Axios.post(`${subUrl}/api/office/login`, formData)
                        .then(subRes => {
                            sessionStorage.setItem('token', res.data.token)
                            sessionStorage.setItem('subToken', subRes.data.token)
                            this.setState({
                                user: res.data.user,
                                isLoading: false,
                                redirect: true
                            })
                        })
                    }
                },

                (err) => {
                    this.setState({isLoading: false})
                    console.log('error', err);
                }
            )
    }

    renderErrorFromApi = () => {
        const {errorApi} = this.state;
        return (
            <div className="alert alert-danger">
                {errorApi}
            </div>
        )
    }

    render() {
        const { error, redirect, isLoading, user } = this.state;
        if(redirect) {
            let redirectTo = '';
            switch (user.is_admin) {
                case 1:
                    redirectTo = '/journaux'
                    break;

                case 3:
                    redirectTo = '/chroniques'
                    break;
            
                default:
                    redirectTo = '/magazines'
                    break;
            }
            return <Redirect to={redirectTo} />
        }

        return (
            <div className="page-holder d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center py-5">
                        <div className="col-5 col-lg-7 mx-auto mb-5 mb-lg-0">
                            <div className="pr-lg-5"><img src="img/illustration.svg" alt="" className="img-fluid" /></div>
                        </div>
                        <div className="col-lg-5 px-lg-4">
                            {this.state.errorApi !== null ? this.renderErrorFromApi() : ''}
                            <h1 className="text-base text-primary text-uppercase mb-4">Digit-Kiosk Office</h1>
                            <h2 className="mb-4">Welcome back!</h2>
                            <p className="text-muted">Vous vous apprétez à vous connecter sur l'espace de management de digit kiosk </p>
                            <form id="loginForm" className="mt-4" onSubmit={this.handleSubmit}>
                                <div className="form-group mb-4">
                                    <input
                                        type="text"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        placeholder="Adresse Email"
                                        className="form-control border-0 shadow form-control-lg"
                                    />
                                    { error.email !== null ? <small className="form-text text-danger ml-3">{error.email}</small> : null}
                                </div>
                                <div className="form-group mb-4">
                                    <input
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        placeholder="Mot de passe"
                                        className="form-control border-0 shadow form-control-lg text-violet"
                                    />
                                    { error.password !== null ? <small className="form-text text-danger ml-3">{error.password}</small> : null}
                                </div>
                                {/* <div className="form-group mb-4">
                                    <div className="custom-control custom-checkbox">
                                        <input id="customCheck1" type="checkbox" checked className="custom-control-input" />
                                        <label for="customCheck1" className="custom-control-label">Remember Me</label>
                                    </div>
                                </div> */}
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow px-5"
                                    disabled={isLoading}
                                >
                                    {isLoading ?
                                        <div className="d-flex justify-content-center">
                                            <strong>Loading...</strong>
                                        </div>
                                    : 'Se connecter'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;