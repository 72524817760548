import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';
import './Magazines.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class EditMagazine extends Component {
    constructor() {
        super();
        this.state = {
            magazine: {},
            nom: '',
            logo: '',
            couleur: '',
            error: {
                nom: '',
                logo: '',
                couleur: ''
            },
            isMagazineNotExits: false,
            redirect: false,
            isLoading: false,
        }
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const {magazine_id} = this.props.match.params;
        const token = sessionStorage.getItem('token');

        if(token) {
            Axios.get(`${url}/api/office/magazines/${magazine_id}?token=${token}`)
            .then(res => {
                const {data} = res;
                const {status} = data;
                status === 'error' ? this.setState({ isMagazineNotExits: true }) : this.setState({ magazine: data.magazine })
                console.log('old magazine', this.state.magazine);
            });
        } else {
            this.props.history.goBack();
        }

    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'nom':
                this.setState({nom: value});
                break;
            case 'logo':
                this.setState({logo: e.target.files[0]});
                break;
            case 'couleur':
                this.setState({couleur: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const token = sessionStorage.getItem('token');

        const {magazine, nom, logo, couleur, error} = this.state;
        let formData = new FormData();

        formData.append('magazine_id', magazine.id);
        if(nom !== '') {formData.append('nom', nom);}
        if(logo !== '') {formData.append('logo', logo);}
        if(couleur !== '') {formData.append('couleur', couleur);}

        Axios.post(`${url}/api/office/edit-magazine?token=${token}`, formData).then(
            res => {
                const {status} = res.data;
                if(status === 'error') {
                    const {errors} = res.data;
                    let {error} = this.state;

                    if(errors.nom !== undefined) {
                        error.nom = errors.nom[0]
                    } else {error.nom = ''}
                    if(errors.couleur !== undefined) {
                        error.couleur = errors.couleur[0]
                    } else {error.couleur = ''}
                    if(errors.logo !== undefined) {
                        error.logo = errors.logo[0]
                    } else {error.logo = ''}
                    this.setState({isLoading: false, error});
                } else {
                    this.setState({isLoading: false, redirect: true});
                }
            },

            (err) => {
                this.setState({isLoading: false});
                console.log('error', err)
            }
        )
    }

    renderNotExistsAlert = () => {
        return (
            <div className="alert alert-info">La magazine sélectionnée n'existe pas !</div>
        )
    }

    renderForm = () => {
        const {error, isLoading} = this.state;
        return (                           
            <form data-form="multipart" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label>Nouveau Nom de la Magazine</label>
                    <input 
                        type="text"
                        name="nom"
                        value={this.state.nom}
                        placeholder="Nom Magazine"
                        className="form-control"
                        onChange={this.handleChange}
                    />
                    { error.nom !== null ? <small className="form-text text-danger ml-3">{error.nom}</small> : null}
                </div>

                <div className="form-group">       
                    <label>Nouveau Logo Magazine</label>
                    <input
                        type="file"
                        name="logo"
                        className="form-control"
                        onChange={this.handleChange}
                    />
                    { error.logo !== null ? <small className="form-text text-danger ml-3">{error.logo}</small> : null}
                </div>

                <div className="form-group">       
                    <label>Nouvelle Couleur Magazine</label>
                    <input
                        type="text"
                        name="couleur"
                        value={this.state.couleur}
                        placeholder="Nouvelle Couleur Magazine"
                        className="form-control"
                        onChange={this.handleChange}
                    />
                    { error.couleur !== null ? <small className="form-text text-danger ml-3">{error.couleur}</small> : null}
                </div>
                <div className="form-group mt-4">
                    {   isLoading ?
                            <button className="btn btn-lg btn-block btn-primary" disabled>
                                <div className="d-flex justify-content-center">
                                    <strong>Loading...</strong>
                                </div>
                            </button>
                        :   <input type="submit" value="Valider les modifications" className="btn btn-lg btn-block btn-primary" />
                    }
                </div>
            </form>
        )
    }

    renderTable = () => {
        const {magazine} = this.state;
        let imgUrl = `${url}/public/img`;

        return(
            <table className="table table-striped table-bordered card-text">
                <tbody>
                    <tr>
                        <th>Nom</th>
                        <td className="text-center">{magazine.nom}</td>
                    </tr>
                    <tr>
                        <th>Logo</th>
                        <td className="text-center">
                            <img
                                style={{ width: "150px", height: "150px", borderRadius: "10px" }}
                                src={`${imgUrl}/${magazine.logo}`}
                                alt="logo magazine"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Couleur</th>
                        <td className="text-center">{magazine.couleur}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    render() {
        const { redirect, isMagazineNotExits } = this.state;

        if(redirect) {
            return <Redirect to='/magazines' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-magazine" className="row mr-4 ml-3">
                <div className="col-md-12 mb-4">
                    <h2 className="text-center">
                        { isMagazineNotExits ? 'Edition Magazine' : `Edition Magazine ${this.state.magazine.nom}` }
                    </h2>
                </div>

                <div className="col-md-12 card">
                    <div className="col-md-12 pt-3 mx-auto" style={{ minHeight: '70vh' }}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 pt-4">
                                    { isMagazineNotExits ? '' : this.renderTable() }
                                </div>
                                <div className="col-md-5 offset-1">
                                    { isMagazineNotExits ? this.renderNotExistsAlert() : this.renderForm() }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </>
        );
    }
}

export default EditMagazine;