import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import './App.css';

import AppContent from './Pages/app-content/AppContent';
import Login from './Pages/auth/Login';
import Accueil from './Pages/Accueil/Accueil';
import ListeMagazines from './Pages/magazines/ListeMagazines';
import NewMagazine from './Pages/magazines/NewMagazine';
import EditMagazine from './Pages/magazines/EditMagazine';
import Actualites from './Pages/actualites/Actualites';
import NewActualite from './Pages/actualites/NewActualite';
import EditActualite from './Pages/actualites/EditActualite';
import Journaux from './Pages/journaux/Journaux';
import JourneauxByMag from './Pages/journaux/JourneauxByMag';
import NewJournal from './Pages/journaux/NewJournal';
import EditJournal from './Pages/journaux/EditJournal';
import Achats from './Pages/achats/Achats';
import AchatsByAbonnements from './Pages/achats/AchatsByAbonnements';
import AchatsByMagazine from './Pages/achats/AchatsByMagazine';
import Abonnements from './Pages/abonnements/Abonnements';
import Admins from './Pages/admins/Admins';
import NewAdmin from './Pages/admins/NewAdmin';
import Parametres from './Pages/admins/Parametres';
import Chroniqueurs from './Pages/chroniqueurs/Chroniqueurs';
import NewChroniqueur from './Pages/chroniqueurs/NewChroniqueur';
import Chroniques from './Pages/chroniques/Chroniques';
import NewChronique from './Pages/chroniques/NewChronique';
import EditChronique from './Pages/chroniques/EditChronique';
import Tomes from './Pages/tomes/Tomes';
import NewTome from './Pages/tomes/NewTome';
import EditTome from './Pages/tomes/EditTome';
import Categories from './Pages/categories/Categories';
import NewCategorie from './Pages/categories/NewCategorie';
import EditCategorie from './Pages/categories/EditCategorie';

class App extends Component {

	renderRoutes = () => (
		<>
			<Route path='/accueil' exact component={Accueil} />
                            
			<Route path='/magazines' component={ListeMagazines} />
			<Route path='/new-magazine' component={NewMagazine} />
			<Route path='/edit-magazine/:magazine_id' component={EditMagazine} />

			<Route path='/actualites' component={Actualites} />
			<Route path='/new-actualite' component={NewActualite} />
			<Route path='/edit-actualite/:actualite_id' component={EditActualite} />

			<Route path='/journaux' exact component={Journaux} />
			<Route path='/journaux/:magazine_name' exact component={JourneauxByMag} />
			<Route path='/new-journal' component={NewJournal} />
			<Route path='/edit-journal/:journal_id' component={EditJournal} />

			<Route path='/achats' exact component={Achats} />
			<Route path='/achats-by-abonnements' exact component={AchatsByAbonnements} />
			<Route path='/achatsByMagazine/:magazine_name' exact component={AchatsByMagazine} />

			<Route path='/abonnements' exact component={Abonnements} />

			<Route path='/admins' component={Admins} />
			<Route path='/new-admin' component={NewAdmin} />
			<Route path='/settings' component={Parametres} />

			<Route path='/chroniqueurs' component={Chroniqueurs} />
			<Route path='/new-chroniqueur' component={NewChroniqueur} />

			<Route path='/categories' component={Categories} />
			<Route path='/new-categorie' component={NewCategorie} />
			<Route path='/edit-categorie/:categorie_id' component={EditCategorie} />

			<Route path='/chroniques' component={Chroniques} />
			<Route path='/new-chronique' component={NewChronique} />
			<Route path='/edit-chronique/:chronique_id' component={EditChronique} />

			<Route path='/tomes/:chronique_id' component={Tomes} />
			<Route path='/new-tome/:chronique_id' component={NewTome} />
			<Route path='/edit-tome/:tome_id' component={EditTome} />
		</>
	)

	render () {
		return (
			<div className="container-fluid">
				<Router>
					<Switch>
						<Route path='/' exact component={Login} />
	
						<AppContent>
							<Route component={({ match }) =>
								this.renderRoutes()
							}/>
						</AppContent>
					</Switch>
				</Router>
			</div>
		);
	}
}

export default App;
