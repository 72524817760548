import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {url} from '../../config';
import './Achats.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class Achats extends Component {
    constructor () {
        super();
        this.state = {
            profil: '',
            achats: [],
            listeAchats: [],
            magazines: [],
            filter: 'all',
            filter_date: '',
            isNoAchatExist: false,
            isLoading: true,
        };
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        if(token) {
            axios.get(`${url}/api/office/achats/1?token=${token}`)
            .then(res => {
                res.data.count > 0 ?
                    this.setState({
                        magazines: res.data.magazines ?? [],
                        achats: res.data.achats,
                        profil: res.data.is_admin === 2 ? 'super-admin' : res.data.is_admin === 1 ? 'admin' : 'chroniqueur',
                        listeAchats: res.data.achats,
                        isLoading: false
                    })
                :
                    this.setState({ isNoAchatExist: true, isLoading: false })
            });
        } else {
            this.props.history.goBack();
        }
    }

    onFilter = (e) => {
        let { filter, filter_date, achats, listeAchats } = this.state;

        if(e.target.name === 'filter') {
            filter = e.target.value;
        } else if(e.target.name === 'filter_date') {
            filter_date = e.target.value;
        }

        if(filter !== 'all') {
            if(!filter_date) {
                listeAchats = achats.filter(achat => {
                    return achat.magazine === filter ? true : false
                })
            } else {
                listeAchats = achats.filter(achat => {
                    return (achat.magazine === filter) && (achat.date === filter_date) ? true : false
                })
            }
        } else {
            if(!filter_date) {
                listeAchats = achats
            } else {
                listeAchats = achats.filter(achat => {
                    return achat.date === filter_date ? true : false
                })
            }
        }

        this.setState({filter, filter_date, listeAchats});
    }

    activeTab = (e) => {

    }

    renderNoAchatAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucun Achat n'est encore effectué ! 
                </p>
            </div>
        )
    }

    renderTable = () => {
        const {listeAchats, profil} = this.state;
        let imgUrl = `${url}/public`;

        return (
            <>
                <table className="table table-striped card-text">
                    <thead>
                        <tr>
                            <th>Acheteur</th>
                            { profil != 'chroniqueur' ? <th>Magazine</th> : <th>Chronique</th> }
                            { profil != 'chroniqueur' ? <th>Jounal du </th> : <th>Publié le </th> }
                            { profil == 'chroniqueur' ? <th>Prix </th> : '' }
                            <th>Premiere page</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listeAchats ? listeAchats.map((achat, index) => (
                            <tr key={index}>
                                <td>{ achat.acheteur }</td>
                                { profil != 'chroniqueur' ? <td>{ achat.magazine }</td> : <td>{ achat.chronique }</td> }
                                <td>{ achat.date }</td>
                                { profil == 'chroniqueur' ? <td>{ achat.prix } fcfa </td> : '' }
                                <td><img className="achat-list-view" src={achat.premiere_page} alt="premiere page journal" /></td>
                                <td>
                                    <button type="button" className="btn btn-sm btn-info mr-2" data-toggle="modal" data-target={`#achatModal${achat.id}Scrollable`}><i className="fas fa-eye"></i></button>
                                </td>
                            </tr>
                        )) : ''}
                    </tbody>
                </table>

                {
                    listeAchats.length === 0 ?
                        <h4 className="text-center text-secondary">Pas de résultat</h4>
                    :
                    listeAchats.map((achat, index) => (
                        <div key={`${index}s`} className="modal fade" id={`achatModal${achat.id}Scrollable`} tabIndex="-1" role="dialog" aria-labelledby="showContenuModalTitle" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="showContenuModalTitle">Modal title</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <embed src={achat.contenu} width="770" height="500" type='application/pdf'/>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </>
        )
    }

    render() {
        const {isNoAchatExist, isLoading, magazines, profil} = this.state;

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-achats" className="row mr-4">
                        <div className="col-md-12">
                            <div className="clearfix mb-md-3">
                                <h2 className="float-left">Liste des ventes</h2>

                                <div className="float-right">
                                    <div className="form-row">
                                        <div className="col">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="filter_date"
                                                value={this.state.filter_date}
                                                placeholder="Date Ex: 01/10/20"
                                                onChange={this.onFilter}
                                            />
                                        </div>
                                        {
                                            profil === 'super-admin' ?
                                                <div className="col">
                                                    <select name="filter" onChange={this.onFilter} className="form-control">
                                                        <option value="all">Tous les magazines</option>
                                                        {
                                                            magazines ?
                                                                magazines.map((mag, key) => (
                                                                    <option key={key} value={mag.nom}>{mag.nom}</option>
                                                                ))
                                                            : ''
                                                        }
                                                    </select>
                                                </div>
                                            :   ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    {
                                        isLoading ?
                                            <div className="d-flex justify-content-center">
                                                <strong>Loading...</strong>
                                            </div>
                                        :   isNoAchatExist ? this.renderNoAchatAlerte() : this.renderTable()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Achats;