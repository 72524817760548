import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';
import Sidebar from '../../Components/sidebar/Sidebar';

class NewAdmin extends Component {
    constructor() {
        super();
        this.state = {
            magazines: [],
            magazine: '',
            prenom: '',
            avatar: '',
            email: '',
            telephone: '',
            nom: '',
            error: {
                magazine: null,
                prenom: null,
                avatar: null,
                email: null,
                telephone: null,
                nom: null,
            },
            redirect: false,
            isLoading: false,
        }
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(!token) {
            this.props.history.goBack();
        } else {
            Axios.get(`${url}/api/office/magazines?token=${token}`)
            .then(res => {
                this.setState({ magazines: res.data.magazines });
            });
        }
    }

    activeTab = (e) => {
        
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'prenom':
                this.setState({prenom: value});
                break;
            case 'nom':
                this.setState({nom: value});
                break;
            case 'email':
                this.setState({email: value});
                break;
            case 'telephone':
                this.setState({telephone: value});
                break;
            case 'avatar':
                this.setState({avatar: e.target.files[0]});
                break;
            case 'magazine':
                this.setState({magazine: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {magazine, prenom, avatar, email, telephone, nom, error} = this.state;

        if(prenom === '') {
            error.prenom = 'Le prenom de l\'admin est obligatoire';
        } else {
            error.prenom = null;
        }
        if(nom === '') {
            error.nom = 'Le nom de l\'admin est obligatoire';
        } else {
            error.nom = null;
        }
        if(email === '') {
            error.email = 'L\'email de l\'admin est obligatoire';
        } else {
            error.email = null;
        }
        if(telephone === '') {
            error.telephone = 'Le telephone de l\'admin est obligatoire';
        } else {
            error.telephone = null;
        }
        if(avatar === '') {
            error.avatar = 'L\'avatar de l\'admin est obligatoire';
        } else {
            error.avatar = null;
        }
        if(magazine === '') {
            error.magazine = 'Le magazine de l\'admin est obligatoire';
        } else {
            error.magazine = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({error, isLoading: false})
        } else {
            this.sendFormData();
        }
    }

    sendFormData = () => {
        const token = sessionStorage.getItem('token');
        const subToken = sessionStorage.getItem('subToken');
        
        let formData = new FormData();
        formData.append('prenom', this.state.prenom);
        formData.append('nom', this.state.nom);
        formData.append('email', this.state.email);
        formData.append('telephone', this.state.telephone);
        formData.append('avatar', this.state.avatar);
        formData.append('magazine', this.state.magazine);

        Axios.post(`${url}/api/office/new-admin?token=${token}`, formData)
        .then(
            res => {
                const {status} = res.data;
                if(status === 'error') {
                    const {errors} = res.data;
                    let {error} = this.state;

                    if(errors.prenom !== undefined) {
                        error.prenom = errors.prenom[0]
                    } else {error.prenom = ''}
                    if(errors.nom !== undefined) {
                        error.nom = errors.nom[0]
                    } else {error.nom = ''}
                    if(errors.email !== undefined) {
                        error.email = errors.email[0]
                    } else {error.email = ''}
                    if(errors.telephone !== undefined) {
                        error.telephone = errors.telephone[0]
                    } else {error.telephone = ''}
                    if(errors.avatar !== undefined) {
                        error.avatar = errors.avatar[0]
                    } else {error.avatar = ''}
                    if(errors.magazine !== undefined) {
                        error.magazine = errors.magazine[0]
                    } else {error.magazine = ''}

                    this.setState({isLoading: false, error});
                } else {
                    Axios.post(`${subUrl}/api/office/new-admin?token=${subToken}`, formData)
                    .then(subRes => {
                        this.setState({isLoading: false, redirect: true});
                    })
                }
            },

            err => {
                this.setState({isLoading: false});
                console.log('error', err)
            }
        )
    }

    render() {
        const { magazines, error, redirect, isLoading } = this.state;

        if(redirect) {
            return <Redirect to='/admins' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-admin" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">Nouveau Administrateur</h2>
                        </div>

                        <div className="col-md-12 card">
                            <div className="col-md-8 pt-5 mx-auto" style={{ minHeight: '70vh' }}>
                                <div className="card-body">                           
                                    <form data-form="multipart" onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Prénom de l'admin</label>
                                                <input 
                                                    type="text"
                                                    name="prenom"
                                                    value={this.state.prenom}
                                                    placeholder="prenom de l'admin"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.prenom !== null ? <small className="form-text text-danger ml-3">{error.prenom}</small> : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Nom de l'admin</label>
                                                <input 
                                                    type="text"
                                                    name="nom"
                                                    value={this.state.nom}
                                                    placeholder="nom de l'admin"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.nom !== null ? <small className="form-text text-danger ml-3">{error.nom}</small> : null}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Email de l'admin</label>
                                                <input 
                                                    type="email"
                                                    name="email"
                                                    value={this.state.email}
                                                    placeholder="email de l'admin"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.email !== null ? <small className="form-text text-danger ml-3">{error.email}</small> : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Telephone de l'admin</label>
                                                <input 
                                                    type="text"
                                                    name="telephone"
                                                    value={this.state.telephone}
                                                    placeholder="telephone de l'admin"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.telephone !== null ? <small className="form-text text-danger ml-3">{error.telephone}</small> : null}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Avatar de l'admin</label>
                                                <input
                                                    type="file"
                                                    name="avatar"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.avatar !== null ? <small className="form-text text-danger ml-3">{error.avatar}</small> : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Magazine de l'admin</label>
                                                <select 
                                                    type="text"
                                                    name="magazine"
                                                    value={this.state.magazine}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                >
                                                    <option value=''>Selectionner une magazine</option>
                                                    {magazines.map((magazine, index) => (
                                                        <option key={index} value={magazine.id}>{magazine.nom}</option>
                                                    ))}
                                                    
                                                </select>
                                                { error.magazine !== null ? <small className="form-text text-danger ml-3">{error.magazine}</small> : null}
                                            </div>
                                        </div>
                                        <div className="form-group">    
                                            {   isLoading ?
                                                    <button className="btn btn-lg btn-block btn-primary" disabled>
                                                        <div className="d-flex justify-content-center">
                                                            <strong>Loading...</strong>
                                                        </div>
                                                    </button>
                                                :   <input type="submit" value="Ajouter" className="btn btn-block btn-primary" />
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NewAdmin;