import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';
import Sidebar from '../../Components/sidebar/Sidebar';

class Parametres extends Component {
    constructor() {
        super();
        this.state = {
            prenom: '',
            avatar: '',
            email: '',
            telephone: '',
            nom: '',
            password: '',
            error: {
                prenom: null,
                avatar: null,
                email: null,
                telephone: null,
                nom: null,
                password: null
            },
            isLoading: false,
            success: false,
        }
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(!token) {
            this.props.history.goBack();
        }
    }

    activeTab = (e) => {
        
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'prenom':
                this.setState({prenom: value});
                break;
            case 'nom':
                this.setState({nom: value});
                break;
            case 'email':
                this.setState({email: value});
                break;
            case 'telephone':
                this.setState({telephone: value});
                break;
            case 'password':
                this.setState({password: value});
                break;
            case 'avatar':
                this.setState({avatar: e.target.files[0]});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const token = sessionStorage.getItem('token');
        
        let error = { prenom: null, avatar: null, email: null, telephone: null, nom: null, password: null }
        this.setState({error});

        const {prenom, nom, email, telephone, avatar, password} = this.state;
        
        let formData = new FormData();
        if(prenom !== '') {formData.append('prenom', this.state.prenom)};
        if(nom !== '') {formData.append('nom', this.state.nom)};
        if(email !== '') {formData.append('email', this.state.email)};
        if(telephone !== '') {formData.append('telephone', this.state.telephone)};
        if(password !== '') {formData.append('password', this.state.password)};
        if(avatar !== '') {formData.append('avatar', this.state.avatar)};

        Axios.post(`${url}/api/office/settings?token=${token}`, formData)
        .then(
            res => {
                const {status} = res.data;
                if(status === 'error') {
                    const {errors} = res.data;
                    let {error} = this.state;

                    if(errors.prenom !== undefined) {
                        error.prenom = errors.prenom
                    } else {error.prenom = ''}
                    if(errors.nom !== undefined) {
                        error.nom = errors.nom
                    } else {error.nom = ''}
                    if(errors.email !== undefined) {
                        error.email = errors.email
                    } else {error.email = ''}
                    if(errors.telephone !== undefined) {
                        error.telephone = errors.telephone
                    } else {error.telephone = ''}
                    if(errors.password !== undefined) {
                        error.password = errors.avatar
                    } else {error.password = ''}
                    if(errors.avatar !== undefined) {
                        error.avatar = errors.avatar
                    } else {error.avatar = ''}

                    this.setState({isLoading: false, error});
                } else {
                    this.setState({isLoading: false, success: true})
                    setTimeout(() => {
                        this.setState({success: false})
                    }, 2000);
                }
            },

            err => {
                this.setState({isLoading: false});
                console.log('error', err)
            }
        )
    }

    render() {
        const { error, isLoading, success } = this.state;

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-admin" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">Paramètrer mon compte</h2>
                        </div>

                        <div className="col-md-12 card">
                            <div className="col-md-8 pt-5 mx-auto" style={{ minHeight: '70vh' }}>
                                <div className="card-body">
                                    {
                                        success
                                        ?   <div className="alert alert-success">Mise à jour réussi avec succès !</div>
                                        :   ''
                                    }                        
                                    <form data-form="multipart" onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Modifier mon Prénom</label>
                                                <input 
                                                    type="text"
                                                    name="prenom"
                                                    value={this.state.prenom}
                                                    placeholder="Nouveau Prénom"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.prenom !== null ? <small className="form-text text-danger ml-3">{error.prenom}</small> : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Modifier mon Nom</label>
                                                <input 
                                                    type="text"
                                                    name="nom"
                                                    value={this.state.nom}
                                                    placeholder="Nouveau Nom"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.nom !== null ? <small className="form-text text-danger ml-3">{error.nom}</small> : null}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Modifier mon Email</label>
                                                <input 
                                                    type="email"
                                                    name="email"
                                                    value={this.state.email}
                                                    placeholder="Nouvel Email"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.email !== null ? <small className="form-text text-danger ml-3">{error.email}</small> : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Modifier mon Téléphone</label>
                                                <input 
                                                    type="text"
                                                    name="telephone"
                                                    value={this.state.telephone}
                                                    placeholder="Nouveau Téléphone"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.telephone !== null ? <small className="form-text text-danger ml-3">{error.telephone}</small> : null}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Modifier mon Mot de passe</label>
                                                <input 
                                                    type="password"
                                                    name="password"
                                                    value={this.state.password}
                                                    placeholder="Nouveau Mot de passe"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.password !== null ? <small className="form-text text-danger ml-3">{error.password}</small> : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Modifier mon Avatar</label>
                                                <input
                                                    type="file"
                                                    name="Nouveau Avatar"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.avatar !== null ? <small className="form-text text-danger ml-3">{error.avatar}</small> : null}
                                            </div>
                                        </div>
                                        <div className="form-group">    
                                            {   isLoading ?
                                                    <button className="btn btn-lg btn-block btn-primary" disabled>
                                                        <div className="d-flex justify-content-center">
                                                            <strong>Loading...</strong>
                                                        </div>
                                                    </button>
                                                :   <input type="submit" value="Mettre à jour" className="btn btn-block btn-primary" />
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Parametres;