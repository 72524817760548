import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {url, subUrl} from '../../config';
import './Magazines.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class ListeMagazines extends Component {
    constructor () {
        super();
        this.state = {
            magazines: [],
            isNoMagExist: false,
            isLoading: true,
        };
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(token) {
            axios.get(`${url}/api/office/magazines?token=${token}`)
            .then(res => {
                res.data.count > 0 ? this.setState({ magazines: res.data.magazines, isLoading: false }) : this.setState({ isNoMagExist: true, isLoading: false })
            });
        } else {
            this.props.history.goBack();
        }
    }

    onDelete = (id) => {
        this.setState({isLoading: true});
        const token = sessionStorage.getItem('token');

        axios.get(`${url}/api/office/magazines/${id}/remove?token=${token}`)
        .then(res => {
            let magazines = this.state.magazines.filter(magazine => magazine.id !== id);
            this.setState({magazines, isLoading: false});
        });
    }

    renderNewMagAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucun Magazines n'est encore ajouté &nbsp; &nbsp; &nbsp; &nbsp;
                    <Link  to="/new-magazine" className="btn btn-sm btn-primary">Ajouter un Magazine</Link>
                </p>
            </div>
        )
    }

    renderTable = () => {
        const {magazines} = this.state;
        let imgUrl = `${url}/public/img`;

        return (
            <table className="table table-striped card-text">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Magazine</th>
                        <th>couleur</th>
                        <th>Logo</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {magazines.map((magazine, index) => (
                        <tr key={index}>
                            <th scope="row">{ magazine.id }</th>
                            <td>{ magazine.nom }</td>
                            <td>{ magazine.couleur }</td>
                            <td><img className="logo-mag-list-view" src={`${imgUrl}/${magazine.logo}`} alt={`logo ${magazine.nom}`}/></td>
                            <td>
                                <Link to={`/edit-magazine/${magazine.id}`} className="btn btn-sm btn-warning mr-2">Editer</Link>
                                <button className="btn btn-sm btn-danger" onClick={() => this.onDelete(magazine.id)}>Supprimer</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    render() {
        const {isNoMagExist, isLoading} = this.state;

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-magazines" className="row mr-4">
                <div className="col-md-12">
                    <div className="clearfix mb-md-3">
                       <h2 className="float-left">Liste des Magazines</h2>
                       <Link  to="/new-magazine" className="btn btn-md btn-primary float-right">Nouvelle Magazine</Link>
                    </div>
                </div>

                <div className="col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            { 
                                isLoading ?
                                    <div className="d-flex justify-content-center">
                                        <strong>Loading...</strong>
                                    </div>
                                :   isNoMagExist ? this.renderNewMagAlerte() : this.renderTable()
                                  
                            }
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </>
        );
    }
}

export default ListeMagazines;