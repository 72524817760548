import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';
import Sidebar from '../../Components/sidebar/Sidebar';

class NewActualite extends Component {
    constructor() {
        super();
        this.state = {
            titre: '',
            provenance: '',
            type: '',
            description: '',
            contenu: '',
            commentable: 'non',
            error: {
                titre: null,
                provenance: null,
                type: null,
                description: null,
                commentable: null,
                contenu: null
            },
            redirect: false,
            isLoading: false,
        }
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(!token) {
            this.props.history.goBack();
        }
    }

    activeTab = (e) => {

    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'titre':
                this.setState({titre: value});
                break;
            case 'provenance':
                this.setState({provenance: value});
                break;
            case 'type':
                this.setState({type: value});
                if(value === 'video') {
                    this.setState({provenance: 'externe', commentable: 'oui'});
                } else {
                    this.setState({provenance: '', commentable: 'non'});
                }
                break;
            case 'description':
                this.setState({description: value});
                break;
            case 'commentable':
                this.setState({commentable: value});
                break;
            case 'contenu':
                if(this.state.provenance === 'interne') {
                    this.setState({contenu: e.target.files[0]});
                } else if(this.state.provenance === 'externe') {
                    this.setState({contenu: value});
                }
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        let resetError = {titre: null, provenance: null, type: null, description: null, contenu: null,};
        this.setState({error: resetError});

        const {titre, provenance, type, commentable, contenu, error} = this.state;

        if(titre === '') {
            error.titre = 'Le titre de l\'actualite est obligatoire';
        } else {
            error.titre = null;
        }
        if(type === '') {
            error.type = 'Le type de media de l\'actualite est obligatoire';
        } else {
            error.type = null;
        }
        if(provenance === '') {
            error.provenance = 'La provenance de l\'actualite est obligatoire';
        } else {
            error.provenance = null;
        }
        if(contenu === '') {
            error.contenu = 'Le contenu de l\'actualite est obligatoire';
        } else {
            error.contenu = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({error, isLoading: false})
        } else {
            this.sendFormData();
        }
    }

    sendFormData = () => {
        const token = sessionStorage.getItem('token');
        const subToken = sessionStorage.getItem('subToken');

        let formData = new FormData();
        formData.append('titre', this.state.titre);
        formData.append('type', this.state.type);
        formData.append('provenance', this.state.provenance);
        formData.append('description', this.state.description);
        if (this.state.commentable === 'oui') {
            formData.append('commentable', 1);
        } else {
            formData.append('commentable', 0);
        }
        formData.append('contenu', this.state.contenu);

        Axios.post(`${url}/api/office/new-actualite?token=${token}`, formData)
        .then(
            res => {
                const {status} = res.data;
                if(status === 'error') {
                    const {errors} = res.data;
                    let {error} = this.state;

                    if(errors.titre !== undefined) {
                        error.titre = errors.titre[0]
                    } else {error.titre = null}

                    if(errors.type !== undefined) {
                        error.type = errors.type[0]
                    } else {error.type = null}

                    if(errors.provenance !== undefined) {
                        error.provenance = errors.provenance[0]
                    } else {error.provenance = null}

                    if(errors.commentable !== undefined) {
                        error.commentable = errors.commentable[0]
                    } else {error.commentable = null}

                    if(errors.contenu !== undefined) {
                        error.contenu = errors.contenu[0]
                    } else {error.contenu = null}

                    this.setState({error, isLoading: false});
                } else {
                    Axios.post(`${subUrl}/api/office/new-actualite?token=${subToken}`, formData)
                    .then(subRes => {
                        this.setState({isLoading: false, redirect: true});
                    });
                }
            },

            err => {
                this.setState({isLoading: false});
                console.log('error', err)
            }
        )
    }

    renderContenuField = () => {
        const { error } = this.state;

        if(this.state.provenance === 'interne') {
            return (
                <>
                    <label>Contenu de l'actualité</label>
                    <input
                        type="file"
                        name="contenu"
                        className="form-control"
                        onChange={this.handleChange}
                    />
                    { error.contenu !== null ? <small className="form-text text-danger ml-3">{error.contenu}</small> : null}
                </>
            )
        } else if(this.state.provenance === 'externe') {
            let label = this.state.type === 'video' ? 'Lien du video Youtube' : 'Contenu de l\'actualité';
            return (
                <>
                    <label>{ label }</label>
                    <input
                        type="text"
                        name="contenu"
                        placeholder="Collez le lien ici ..."
                        value={this.state.contenu}
                        className="form-control"
                        onChange={this.handleChange}
                    />
                    { error.contenu !== null ? <small className="form-text text-danger ml-3">{error.contenu}</small> : null}
                </>
            )
        }

    }

    render() {
        const { error, redirect, isLoading } = this.state;

        if(redirect) {
            return <Redirect to='/actualites' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-actualite" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">Nouvelle Actualité</h2>
                        </div>

                        <div className="col-md-12 card">
                            <div className="col-md-8 pt-3 mx-auto" style={{ minHeight: '70vh' }}>
                                <div className="card-body">
                                    <form data-form="multipart" onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-6">
                                                <label>Titre de l'Actualité</label>
                                                <input
                                                    type="text"
                                                    name="titre"
                                                    placeholder="Titre de votre actu ..."
                                                    value={this.state.titre}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.titre !== null ? <small className="form-text text-danger ml-3">{error.titre}</small> : null}
                                            </div>

                                            <div className="form-group col-6">
                                                <label>Type de média de l'Actualité</label>
                                                <select
                                                    name="type"
                                                    value={this.state.type}
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                >
                                                    <option value=''>Choisir le type de contenu</option>
                                                    <option value='image'>Image</option>
                                                    <option value='video'>Vidéo</option>
                                                </select>
                                                { error.type !== null ? <small className="form-text text-danger ml-3">{error.type}</small> : null}
                                            </div>
                                        </div>
                                        {
                                            this.state.type === 'image'

                                            ?   <div className="form-row">
                                                    <div className="form-group col-6">
                                                        <label>Nature de l'Actualité</label>
                                                        <select
                                                            name="provenance"
                                                            value={this.state.provenance}
                                                            className="form-control"
                                                            onChange={this.handleChange}
                                                        >
                                                            <option value=''>Choisir la nature de l'actu</option>
                                                            <option value='externe'>Lien externe</option>
                                                            <option value='interne'>Fait maison</option>
                                                        </select>
                                                        { error.provenance !== null ? <small className="form-text text-danger ml-3">{error.provenance}</small> : null}
                                                    </div>

                                                    <div className="form-group col-6">
                                                        <label>L'actualité est-elle commentable ?</label>
                                                        <select
                                                            name="commentable"
                                                            value={this.state.commentable}
                                                            className="form-control"
                                                            onChange={this.handleChange}
                                                        >
                                                            <option value='non'>Non</option>
                                                            <option value='oui'>Oui</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            : ''
                                        }

                                        <div className="form-group">
                                            { this.state.provenance !== '' ? this.renderContenuField() : '' }
                                        </div>

                                        <div className="form-group">
                                            <label>Description de l'Actualité</label>
                                            <textarea
                                                name="description"
                                                value={this.state.description}
                                                placeholder="description Actualité"
                                                className="form-control"
                                                onChange={this.handleChange}
                                                rows="6"
                                            ></textarea>
                                            { error.description !== null ? <small className="form-text text-danger ml-3">{error.description}</small> : null}
                                        </div>

                                        <div className="form-group">
                                            {   isLoading ?
                                                    <button className="btn btn-lg btn-block btn-primary" disabled>
                                                        <div className="d-flex justify-content-center">
                                                            <strong>Loading...</strong>
                                                        </div>
                                                    </button>
                                                :   <input type="submit" value="Ajouter" className="btn btn-block btn-primary" />
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NewActualite;