import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';
import './Chroniques.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class NewChronique extends Component {
    constructor() {
        super();
        this.state = {
            categories: [],
            titre: '',
            description: '',
            categorie_id: '',
            error: {
                titre: null,
                description: null,
                categorie_id: null
            },
            redirect: false,
            isLoading: false,
        }
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(!token) {
            this.props.history.goBack();
        } else {
            this.getCategories(token);
        }
    }

    getCategories(token) {
        Axios.get(`${url}/api/office/categories?token=${token}`)
        .then(res => {
            this.setState({ 
                categories: res.data.categories, 
                isLoading: false 
            })
        });
    }

    activeTab = (e) => {
        
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'titre':
                this.setState({titre: value});
                break;
            case 'description':
                this.setState({description: value});
                break;
            case 'categorie_id':
                this.setState({categorie_id: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {titre, description, categorie_id, error} = this.state;

        if(titre === '') {
            error.titre = 'Le titre de la chronique est obligatoire';
        } else {
            error.titre = null;
        }
        if(description === '') {
            error.description = 'La description de la chronique est obligatoire';
        } else {
            error.description = null;
        }
        if(categorie_id === '') {
            error.categorie_id = 'La catégorie de la chronique est obligatoire';
        } else {
            error.categorie_id = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({error, isLoading: false})
        } else {
            this.sendFormData(titre, description, categorie_id);
        }
    }

    sendFormData = (titre, description, categorie_id) => {
        const token = sessionStorage.getItem('token');
        const subToken = sessionStorage.getItem('subToken');
        
        let formData = new FormData();
        formData.append('titre', titre);
        formData.append('description', description);
        formData.append('categorie_id', categorie_id);

        Axios.post(`${url}/api/office/new-chronique?token=${token}`, formData)
            .then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {
                        const {errors} = res.data;
                        let {error} = this.state;

                        if(errors.titre !== undefined) {
                            error.titre = errors.titre[0]
                        } else {error.titre = ''}
                        if(errors.description !== undefined) {
                            error.description = errors.description[0]
                        } else {error.description = ''}
                        if(errors.categorie_id !== undefined) {
                            error.categorie_id = errors.categorie_id[0]
                        } else {error.categorie_id = ''}

                        this.setState({isLoading: false, error});
                    } else {
                        Axios.post(`${subUrl}/api/office/new-chronique?token=${subToken}`, formData)
                        .then(subRes => {
                            this.setState({isLoading: false, redirect: true});
                        })
                    }
                },

                (err) => {
                    this.setState({isLoading: false});
                    console.log('error', err)
                }
            )
    }

    render() {
        const { error, redirect, isLoading, categories } = this.state;

        if(redirect) {
            return <Redirect to='/chroniques' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-chronique" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">Nouvelle Chronique</h2>
                        </div>

                        <div className="col-md-12 card">
                            <div className="col-md-6 pt-5 mx-auto" style={{ minHeight: '70vh' }}>
                                <div className="card-body">                           
                                    <form data-form="multipart" onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label>Titre de la Chronique</label>
                                            <input 
                                                type="text"
                                                name="titre"
                                                value={this.state.titre}
                                                placeholder="Titre Chronique"
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                            { error.titre !== null ? <small className="form-text text-danger ml-3">{error.titre}</small> : null}
                                        </div>

                                        <div className="form-group">
                                            <label>Catégorie de la Chronique</label>
                                            <select 
                                                name="categorie_id"
                                                value={this.state.categorie_id}
                                                className="form-control"
                                                onChange={this.handleChange}
                                            >
                                                <option value=''>Catégorie Chronique</option>
                                                {
                                                    categories.map((categorie, index) => (
                                                        <option key={index} value={categorie.id}>{categorie.nom}</option>
                                                    ))
                                                }
                                            </select>
                                            { error.categorie_id !== null ? <small className="form-text text-danger ml-3">{error.categorie_id}</small> : null}
                                        </div>

                                        <div className="form-group">
                                            <label>Description de la Chronique</label>
                                            <textarea 
                                                type="text"
                                                name="description"
                                                value={this.state.description}
                                                placeholder="Description Chronique"
                                                className="form-control"
                                                onChange={this.handleChange}
                                                rows="6"
                                            >
                                            </textarea>
                                            { error.description !== null ? <small className="form-text text-danger ml-3">{error.description}</small> : null}
                                        </div>
                                        <div className="form-group mt-4">
                                            {   isLoading ?
                                                    <button className="btn btn-lg btn-block btn-primary" disabled>
                                                        <div className="d-flex justify-content-center">
                                                            <strong>Loading...</strong>
                                                        </div>
                                                    </button>
                                                :   <input type="submit" className="btn btn-lg btn-block btn-primary" value="Ajouter" />
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NewChronique;