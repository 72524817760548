import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';
import Sidebar from '../../Components/sidebar/Sidebar';

class NewJournal extends Component {
    constructor() {
        super();
        this.state = {
            premiere_page: '',
            contenu: '',
            quantite: '',
            prix: '',
            parution: '',
            error: {
                premiere_page: null,
                contenu: null,
                quantite: null,
                prix: null,
                parution: null,
            },
            redirect: false,
            isLoading: false,
        }
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(!token) {
            this.props.history.goBack();
        }
        
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'premiere_page':
                this.setState({premiere_page: e.target.files[0]});
                break;
            case 'contenu':
                this.setState({contenu: e.target.files[0]});
                break;
            case 'quantite':
                this.setState({quantite: value});
                break;
            case 'prix':
                this.setState({prix: value});
                break;
            case 'parution':
                this.setState({parution: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {premiere_page, contenu, quantite, prix, parution, error} = this.state;

        if(premiere_page === '') {
            error.premiere_page = 'Le premiere page du journal est obligatoire';
        } else {
            error.premiere_page = null;
        }
        if(contenu === '') {
            error.contenu = 'Le contenu du journal est obligatoire';
        } else {
            error.contenu = null;
        }
        if(quantite === '') {
            error.quantite = 'Le quantite du journal est obligatoire';
        } else {
            error.quantite = null;
        }
        if(prix === '') {
            error.prix = 'Le prix du journal est obligatoire';
        } else {
            error.prix = null;
        }
        if(parution === '') {
            error.parution = 'La parution du journal est obligatoire';
        } else {
            error.parution = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({error, isLoading: false})
        } else {
            this.sendFormData();
        }
    }

    sendFormData = () => {
        const token = sessionStorage.getItem('token');
        const subToken = sessionStorage.getItem('subToken');
        
        let formData = new FormData();
        formData.append('premiere_page', this.state.premiere_page);
        formData.append('contenu', this.state.contenu);
        formData.append('quantite', this.state.quantite);
        formData.append('prix', this.state.prix);
        formData.append('parution', this.state.parution);

        Axios.post(`${url}/api/office/new-journal?token=${token}`, formData)
            .then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {
                        const {errors} = res.data;
                        let {error} = this.state;

                        if(errors.premiere_page !== undefined) {
                            error.premiere_page = errors.premiere_page[0]
                        } else {error.premiere_page = ''}
                        if(errors.contenu !== undefined) {
                            error.contenu = errors.contenu[0]
                        } else {error.contenu = ''}
                        if(errors.quantite !== undefined) {
                            error.quantite = errors.quantite[0]
                        } else {error.quantite = ''}
                        if(errors.prix !== undefined) {
                            error.prix = errors.prix[0]
                        } else {error.prix = ''}
                        if(errors.parution !== undefined) {
                            error.parution = errors.parution[0]
                        } else {error.parution = ''}

                        this.setState({isLoading: false, error});
                    } else {
                        Axios.post(`${subUrl}/api/office/new-journal?token=${subToken}`, formData)
                        .then(subRes => {
                            this.setState({isLoading: false, redirect: true});
                        })
                    }
                },

                err => {
                    this.setState({isLoading: false});
                    console.log('error', err);
                }
            )
    }

    render() {
        const { error, redirect, isLoading } = this.state;

        if(redirect) {
            return <Redirect to='/journaux' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-journal" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">Nouveau Journal</h2>
                        </div>

                        <div className="col-md-12 card">
                            <div className="col-md-8 pt-4 mx-auto" style={{ minHeight: '70vh' }}>
                                <div className="card-body">                           
                                    <form data-form="multipart" onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Quantité du Journal</label>
                                                <input 
                                                    type="number"
                                                    name="quantite"
                                                    value={this.state.quantite}
                                                    placeholder="quantite Journal"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.quantite !== null ? <small className="form-text text-danger ml-3">{error.quantite}</small> : null}
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Prix du Journal</label>
                                                <input 
                                                    type="number"
                                                    name="prix"
                                                    value={this.state.prix}
                                                    placeholder="prix Journal"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.prix !== null ? <small className="form-text text-danger ml-3">{error.prix}</small> : null}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-6">
                                                <label>Première page du Journal</label>
                                                <input
                                                    type="file"
                                                    name="premiere_page"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.premiere_page !== null ? <small className="form-text text-danger ml-3">{error.premiere_page}</small> : null}
                                            </div>
                                            <div className="form-group col-6">
                                                <label>Contenu du journal</label>
                                                <input
                                                    type="file"
                                                    name="contenu"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                />
                                                { error.contenu !== null ? <small className="form-text text-danger ml-3">{error.contenu}</small> : null}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Date de prution du Journal</label>
                                            <input 
                                                type="date"
                                                name="parution"
                                                value={this.state.parution}
                                                placeholder="date de parution Journal"
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                            { error.parution !== null ? <small className="form-text text-danger ml-3">{error.parution}</small> : null}
                                        </div>
                                        <div className="form-group mt-3">
                                            {   isLoading ?
                                                    <button className="btn btn-lg btn-block btn-primary" disabled>
                                                        <div className="d-flex justify-content-center">
                                                            <strong>Loading...</strong>
                                                        </div>
                                                    </button>
                                                :   <input type="submit" value="Ajouter" className="btn btn-block btn-primary" />
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NewJournal;