import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {url, subUrl} from '../../config';
import './Admins.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class Admins extends Component {
    constructor () {
        super();
        this.state = {
            admins: [],
            isNoAdminExist: false,
            isLoading: true,
        };
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        this.getAdmins();
    }

    getAdmins = () => {
        const token = sessionStorage.getItem('token');
        if(token) {
            axios.get(`${url}/api/office/admins?token=${token}`)
            .then(res => {
                res.data.count > 0 ? this.setState({ admins: res.data.data, isLoading: false }) : this.setState({ isNoAdminExist: true, isLoading: false })
            });
        } else {
            this.props.history.goBack();
        }

    }

    onToggleAdminStatus = (id) => {
        const token = sessionStorage.getItem('token');

        axios.get(`${url}/api/office/toggle-admin-status/${id}?token=${token}`)
        .then(res => { this.getAdmins(); });
    }

    renderNewAdminAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucun Administrateur n'est encore ajouté &nbsp; &nbsp; &nbsp; &nbsp;
                    <Link  to="/new-admin" className="btn btn-sm btn-primary">Ajouter un Administrateur</Link>
                </p>
            </div>
        )
    }

    renderTable = () => {
        const {admins} = this.state;
        let imgUrl = `${url}/public/img`;
        return (
            <table className="table table-striped card-text">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Prénom</th>
                        <th>Nom</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {admins.map((admin, index) => (
                        <tr key={index}>
                            <td><img className="avatar-list-view" src={`${imgUrl}/${admin.avatar}`} alt={`Avatar administrateur`}/></td>
                            <td>{ admin.prenom }</td>
                            <td>{ admin.nom }</td>
                            <td>{ admin.email }</td>
                            <td>{ admin.telephone }</td>
                            <td>
                                { admin.status ? <span className='text-success'>Actif</span> : <span className='text-danger'>Inactif</span> }
                            </td>
                            <td>
                                {
                                    admin.status
                                    ?   <button className="btn btn-sm btn-danger mr-2" onClick={() => this.onToggleAdminStatus(admin.id)}>Désactiver</button>
                                    :   <button className="btn btn-sm btn-success" onClick={() => this.onToggleAdminStatus(admin.id)}>Activer</button>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    render() {
        const {isNoAdminExist, isLoading} = this.state;

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-admins" className="row mr-4">
                <div className="col-md-12">
                    <div className="clearfix mb-md-3">
                       <h2 className="float-left">Liste des administrateurs</h2>
                       <Link to="/new-admin" className="btn btn-md btn-primary float-right">Nouveau Admin</Link>
                    </div>
                </div>

                <div className="col-md-12 mb-4">
                    <div className="card">
                        <div className="card-body">
                            { 
                                isLoading ?
                                    <div className="d-flex justify-content-center">
                                        <strong>Loading...</strong>
                                    </div>
                                :   isNoAdminExist ? this.renderNewAdminAlerte() : this.renderTable()
                            }
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </>
        );
    }
}

export default Admins;