import React, { Component } from 'react';
import axios from 'axios';

import {url} from '../../config';
import './Abonnements.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class Abonnements extends Component {
    constructor () {
        super();
        this.state = {
            abonnements: [],
            isNoAbonnementExist: false,
            isLoading: true,
        };
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        if(token) {
            axios.get(`${url}/api/office/abonnements?token=${token}`)
            .then(res => {
                res.data.count > 0 ?
                    this.setState({
                        abonnements: res.data.abonnements,
                        isLoading: false
                    })
                :
                    this.setState({ isNoAbonnementExist: true, isLoading: false })
            });
        } else {
            this.props.history.goBack();
        }
    }

    activeTab = (e) => {

    }

    renderNoAbonnementAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucun Abonnement n'est encore effectué ! 
                </p>
            </div>
        )
    }

    renderTable = () => {
        const { abonnements } = this.state;

        return (
            <table className="table table-striped card-text">
                <thead>
                    <tr>
                        <th>Abonné</th>
                        <th>Magazine</th>
                        <th>Durée</th>
                        <th>Débuté le</th>
                        <th>Fini le</th>
                        <th>Etat</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        abonnements ? abonnements.map((abonnement, index) => (
                            <tr key={index}>
                                <td>{ abonnement.auteur }</td>
                                <td>{ abonnement.magazine }</td>
                                <td>{ abonnement.formule.duree } jours</td>
                                <td>{ abonnement.debut }</td>
                                <td>{ abonnement.fin }</td>
                                <td className={abonnement.status === 'terminé' ? 'text-danger' : ''}>
                                    { abonnement.status }
                                </td>
                            </tr>
                        )) : ''
                    }
                </tbody>
            </table>
        )
    }

    render() {
        const {isNoAbonnementExist, isLoading} = this.state;

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-abonnements" className="row mr-4">
                        <div className="col-md-12 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    {
                                        isLoading ?
                                            <div className="d-flex justify-content-center">
                                                <strong>Loading...</strong>
                                            </div>
                                        :   isNoAbonnementExist ? this.renderNoAbonnementAlerte() : this.renderTable()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Abonnements;