import React, { Component } from 'react';

import './AppContent.css';
import Navbar from '../../Components/navbar/Navbar';

class AppContent extends Component {
    render() {
        return (
            <div className="row app-content">
                <Navbar />
                { this.props.children }
            </div>
        );
    }
}

export default AppContent;