import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';
import './Categories.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class NewCategorie extends Component {
    constructor() {
        super();
        this.state = {
            nom: '',
            error: {
                nom: null,
            },
            redirect: false,
            isLoading: false,
        }
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');

        if(!token) {
            this.props.history.goBack();
        }
    }

    activeTab = (e) => {
        
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'nom':
                this.setState({nom: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        const {nom, error} = this.state;

        if(nom === '') {
            error.nom = 'Le nom de la catégorie est obligatoire';
        } else {
            error.nom = null;
        }

        let hasError = Object.values(error).find(error => {
            return (error !== null);
        })

        if(hasError !== undefined) {
            this.setState({error, isLoading: false})
        } else {
            this.sendFormData(nom);
        }
    }

    sendFormData = (nom) => {
        const token = sessionStorage.getItem('token');
        const subToken = sessionStorage.getItem('subToken');
        
        let formData = new FormData();
        formData.append('nom', nom);

        Axios.post(`${url}/api/office/new-categorie?token=${token}`, formData)
            .then(
                res => {
                    const {status} = res.data;
                    if(status === 'error') {
                        const {errors} = res.data;
                        let {error} = this.state;

                        if(errors.nom !== undefined) {
                            error.nom = errors.nom[0]
                        } else {error.nom = ''}

                        this.setState({isLoading: false, error});
                    } else {
                        Axios.post(`${subUrl}/api/office/new-categorie?token=${subToken}`, formData)
                        .then(subRes => {
                            this.setState({isLoading: false, redirect: true});
                        })
                    }
                },

                (err) => {
                    this.setState({isLoading: false});
                    console.log('error', err)
                }
            )
    }

    render() {
        const { error, redirect, isLoading } = this.state;

        if(redirect) {
            return <Redirect to='/categories' />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-categorie" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">Nouvelle Catégorie</h2>
                        </div>

                        <div className="col-md-12 card">
                            <div className="col-md-6 pt-5 mx-auto" style={{ minHeight: '70vh' }}>
                                <div className="card-body">                           
                                    <form data-form="multipart" onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label>Nom de la Catégorie</label>
                                            <input 
                                                type="text"
                                                name="nom"
                                                value={this.state.nom}
                                                placeholder="Nom Catégorie"
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                            { error.nom !== null ? <small className="form-text text-danger ml-3">{error.nom}</small> : null}
                                        </div>
                                        <div className="form-group mt-4">
                                            {   isLoading ?
                                                    <button className="btn btn-lg btn-block btn-primary" disabled>
                                                        <div className="d-flex justify-content-center">
                                                            <strong>Loading...</strong>
                                                        </div>
                                                    </button>
                                                :   <input type="submit" className="btn btn-lg btn-block btn-primary" value="Ajouter" />
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default NewCategorie;