import React, { Component } from 'react';

import {url} from '../../config';
import './Accueil.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class Accueil extends Component {

    activeTab = (e) => {
        
    }

    render() {
        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                
                <div className="col-md-9 px-0 py-4">
                    <div className="bg-white shadow row mr-4 ml-3 pl-3 pt-3">
                        <h5>Dashboard</h5>
                        
                        <div className="col-8 mx-auto my-5">
                            <div className="alert alert-info">
                                <p style={{ textAlign: 'center' }}>
                                    Pas d'informations disponibles actuellement 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Accueil;