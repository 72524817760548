import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';
import './Tomes.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class EditTome extends Component {
    constructor() {
        super();
        this.state = {
            tome: {},
            numero: '',
            premiere_page: '',
            contenu: '',
            description: '',
            prix: '',
            publication: '',
            formule: '',
            redirect: false,
            isTomeNotExits: false,
            isLoading: false,
        }
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const {tome_id} = this.props.match.params;
        const token = sessionStorage.getItem('token');

        if(token) {
            Axios.get(`${url}/api/office/get-tome/${tome_id}?token=${token}`)
            .then(res => {
                const {data} = res;
                if(data.status === 'error') { this.setState({ isTomeNotExits: true }) }
                else {
                    this.setState({ tome: data.tome });
                }
            });
        } else {
            this.props.history.goBack();
        }
    }

    handleChange = (e) => {
        const {name, value} = e.target;

        switch (name) {
            case 'premiere_page':
                this.setState({premiere_page: e.target.files[0]});
                break;
            case 'contenu':
                this.setState({contenu: e.target.files[0]});
                break;
            case 'numero':
                this.setState({numero: value});
                break;
            case 'description':
                this.setState({description: value});
                break;
            case 'formule':
                this.setState({formule: value});
                break;
            case 'prix':
                this.setState({prix: value});
                break;
            case 'publication':
                this.setState({publication: value});
                break;
            default:
                break;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});

        const token = sessionStorage.getItem('token');
        
        const {tome, premiere_page, contenu, numero, description, prix, publication, formule} = this.state;

        let formData = new FormData();
        formData.append('tome_id', tome.id);
        if(premiere_page !== '') {formData.append('premiere_page', premiere_page);}
        if(contenu !== '') {formData.append('contenu', contenu);}
        if(numero !== '') {formData.append('numero', numero);}
        if(description !== '') {formData.append('description', description);}
        if(publication !== '') {formData.append('publication', publication);}
        if(formule !== '') {formData.append('formule', formule);}
        if(formule === 'gratuit') {
            formData.append('prix', 0);
        } else {
            if(prix !== '') {formData.append('prix', prix);}
        }

        Axios.post(`${url}/api/office/edit-tome?token=${token}`, formData)
        .then(
            res => {
                const {status} = res.data;
                this.setState({isLoading: false, redirect: true});
            },

            err => {
                this.setState({isLoading: false});
                console.log('error', err)
            }
        )
    }

    renderNotExistsAlert = () => {
        return (
            <div className="alert alert-info">Le Tome sélectionné n'existe pas !</div>
        )
    }

    renderForm = () => {
        const {isLoading} = this.state;
        return(
            <div className="col-md-12 card">
                { this.state.isTomeNotExits ? '' : this.renderTable() }
                <div className="col-md-8 pt-4 mx-auto" style={{ minHeight: '70vh' }}>
                    <div className="card-body">                           
                        <form data-form="multipart" onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Numero du Tome</label>
                                    <input 
                                        type="number"
                                        name="numero"
                                        value={this.state.numero}
                                        placeholder="numero Tome"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Première page du Tome</label>
                                    <input
                                        type="file"
                                        name="premiere_page"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-6">
                                    <label>Contenu du Tome</label>
                                    <input
                                        type="file"
                                        name="contenu"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label>Date de publication du Tome</label>
                                    <input 
                                        type="date"
                                        name="publication"
                                        value={this.state.publication}
                                        placeholder="date de publication Tome"
                                        className="form-control"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div 
                                    className={`form-group form-check ${this.state.formule !== 'gratuit' ? 'col-6' : 'col-12' }`}
                                >
                                    <label>Nature du Tome</label>
                                    <select 
                                        type="text"
                                        name="formule"
                                        value={this.state.formule}
                                        className="form-control"
                                        onChange={this.handleChange}
                                    >
                                        <option value=''>Nouveau Formule</option>
                                        <option value='payant'>Tome payant</option>
                                        <option value='gratuit'>Tome gratuit</option>
                                    </select>
                                </div>
                                {
                                    this.state.formule !== 'gratuit'
                                    ?   
                                        <div className="form-group col-md-6">
                                            <label>Prix du Tome</label>
                                            <input 
                                                type="number"
                                                name="prix"
                                                value={this.state.prix}
                                                placeholder="prix Tome"
                                                className="form-control"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        
                                    :   ''
                                }
                            </div>                            
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label>Description du Tome</label>
                                    <textarea 
                                        type="number"
                                        name="description"
                                        value={this.state.description}
                                        placeholder="description Tome"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        rows="5"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="form-group mt-3">    
                                {   isLoading ?
                                        <button className="btn btn-lg btn-block btn-primary" disabled>
                                            <div className="d-flex justify-content-center">
                                                <strong>Loading...</strong>
                                            </div>
                                        </button>
                                    :   <input type="submit" value="Mettre à jour" className="btn btn-block btn-primary" />
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    renderTable = () => {
        const {tome} = this.state;
        // let imgUrl = `${url}/img`;
        let imgUrl = `${url}/public/img`;

        return (
            <>
                <table className="table table-striped card-text">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Numéro</th>
                            <th>Premiere page</th>
                            <th>Formule</th>
                            <th>Prix</th>
                            <th>Vendus</th>
                            <th>Publication</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ tome.description ? tome.description : ''}</td>
                            <td>{ tome.numero ? tome.numero : ''}</td>
                            <td><img className="tome-page-list-view" src={`${imgUrl}/${tome.premiere_page}`} alt="premiere page tome" /></td>
                            <td>{ tome.formule }</td>
                            <td>{ tome.prix } f</td>
                            <td>{ tome.quantite_vendu }</td>
                            <td>{ tome.publication }</td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-info mr-2"
                                    data-toggle="modal"
                                    data-target={`#editModal${tome.id}${tome.publication}Scrollable`}
                                ><i className="fas fa-eye"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="modal fade" id={`editModal${tome.id}${tome.publication}Scrollable`} tabIndex="-1" role="dialog" aria-labelledby="showContenuModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="showContenuModalTitle">Modal title</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <embed src={`${url}/chroniques/${tome.contenu}`} width="770" height="500" type='application/pdf'/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render() {
        const { tome, redirect, isTomeNotExits } = this.state;

        if(redirect) {
            return <Redirect to={`/tomes/${tome.chronique_id}`} />
        }

        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="new-tome" className="row mr-4 ml-3">
                        <div className="col-md-12 mb-4">
                            <h2 className="text-center">Edition Tome</h2>
                        </div>

                        { isTomeNotExits ? this.renderNotExistsAlert() : this.renderForm() }
                
                    </div>
                </div>
            </>
        );
    }
}

export default EditTome;