import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import {url} from '../../config';
import './Sidebar.css';

class Sidebar extends Component {
    constructor() {
        super();
        this.state = {
            profil: '',
            magazines: []
        }
    }

    componentDidMount(){
        const token = sessionStorage.getItem('token');
        if(token) {
            Axios.get(`${url}/api/account/me?token=${token}`)
            .then(res => {
                const { is_admin } = res.data.data;
                if(is_admin === 1) {
                    this.setState({profil: 'admin'});
                    Axios.get(`${url}/api/office/magazines?token=${token}`)
                    .then(resMag => {
                        this.setState({ magazines: resMag.data.magazines });
                        console.log('profil', this.state.profil);
                    });
                }
                else if(is_admin === 2) {
                    this.setState({profil: 'super-admin'});
                }
                else if(is_admin === 3) {
                    this.setState({profil: 'chroniqueur'});
                }
            });
        } else {
            this.props.history.goBack();
        }
    }

    getActiveUrl = () => {
        const path = window.location.pathname;
        let links = document.querySelectorAll('.sidebar-link');
        let element = null;

        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            if(link.pathname == path) {
                element = link;
                break;
            }
        }

        let oldElementActive = document.querySelector('.sidebar-link.active');
        if (element !== null) {
            if(oldElementActive && oldElementActive != element) {
                oldElementActive.classList.remove('active')
                element.classList.add('active');
            } else {
                element.classList.add('active');
            }
        }
    }

    renrderSuperAdminSidebar = () => {
        return (
            <>
                <li className="sidebar-list-item">
                    <Link to="/magazines" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-newspaper mr-3 text-gray"></i><span>Magazines</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/achats" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-currency-exchange mr-3 text-gray"></i><span>Ventes directes</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/achats-by-abonnements" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-currency-exchange text-gray"></i><span>Ventes par abonnements</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/admins" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-people mr-3 text-gray"></i><span>Administrateurs</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/categories" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-list-ul mr-3 text-gray"></i><span>Catégories Chroniques</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/chroniqueurs" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-people mr-3 text-gray"></i><span>Chroniqueurs</span>
                    </Link>
                </li>
            </>
        )
    }

    renderAdminSideBar = () => {
        const {magazines} = this.state;
        return (
            <>
                <li className="sidebar-list-item">
                    <Link to="/journaux" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-newspaper mr-3 text-gray"></i><span>Mes Journaux</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/abonnements" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-people mr-3 text-gray"></i><span>Mes abonnés</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/achats" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-currency-exchange mr-3 text-gray"></i><span>Ventes directes</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/achats-by-abonnements" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-currency-exchange mr-3 text-gray"></i><span>Ventes par abonnements</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/actualites" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-person-video2 mr-3 text-gray"></i><span>Mes Actualites</span>
                    </Link>
                </li>
            </>
        )
    }

    renderChroniqueurSideBar = () => {
        return (
            <>
                <li className="sidebar-list-item">
                    <Link to="/chroniques" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-journal-richtext mr-3 text-gray"></i><span>Mes Chroniques</span>
                    </Link>
                </li>
                <li className="sidebar-list-item">
                    <Link to="/achats" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                        <i className="bi bi-currency-exchange mr-3 text-gray"></i><span>Mes Ventes</span>
                    </Link>
                </li>
            </>
        )
    }

    render() {
        const {profil} = this.state;
        this.getActiveUrl();
        return (
            <div id="sidebar-content" className="col-md-3 pl-0">
                <div id="sidebar" className="sidebar py-3">
                    <div className="text-gray-400 text-uppercase px-3 px-lg-4 py-4 font-weight-bold small headings-font-family">MENU</div>
                    <ul className="sidebar-menu list-unstyled">
                        {/* <li className="sidebar-list-item">
                            <Link to="/accueil" className="sidebar-link text-muted" onClick={this.props.activeTab}>
                                <i className="o-home-1 mr-3 text-gray"></i><span>Accueil</span>
                            </Link>
                        </li> */}
                        {
                            profil === 'super-admin'
                            ?   this.renrderSuperAdminSidebar()
                            :   profil === 'admin' 
                                ? this.renderAdminSideBar() 
                                : profil === 'chroniqueur'
                                ? this.renderChroniqueurSideBar() 
                                : ''
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

export default Sidebar;