import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Axios from 'axios';

import {url, subUrl} from '../../config';
import './Navbar.css';

class Navbar extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            redirect: false
        }
    }

    componentDidMount(){
        const token = sessionStorage.getItem('token');
        if(token) {
            Axios.get(`${url}/api/account/me?token=${token}`)
            .then(res => {
                this.setState({user: res.data.data});
            });
        } else {
            this.props.history.goBack();
        }
    }

    logout = (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem('token')
        const subToken = sessionStorage.getItem('subToken')

        Axios.get(`${url}/api/logout?token=${token}`)
        .then(res => {
            Axios.get(`${subUrl}/api/logout?token=${subToken}`)
            .then(subRes => {
                sessionStorage.removeItem('token');
                this.setState({redirect: true})
            });
        });
    }

    render() {
        const { user, redirect } = this.state;
        if(redirect) {
            return <Redirect to='/' />
        }

        return (
            <div className="col-md-12 p-0 sticky-top">
                <nav id="my-nav" className="navbar navbar-expand-lg px-4 py-2 bg-white shadow">
                    <a style={{ cursor: 'pointer' }} className="">
                        <img src="/img/logo.png" style={{ maxWidth: '100px', marginRight: '100px'}} className="img-fluid" />
                    </a>
                    <ul className="ml-auto d-flex align-items-center list-unstyled mb-0">
                        
                        <li className="nav-item dropdown ml-auto">
                            <a id="userInfo" href="http://example.com" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">
                                <img src={user.avatar} alt="Jason Doe" style={{ maxWidth: '2.5rem' }} className="img-fluid rounded-circle shadow" />
                            </a>
                            <div aria-labelledby="userInfo" className="dropdown-menu">
                                <a style={{ cursor: 'pointer' }} className="dropdown-item">
                                    <strong className="d-block text-uppercase headings-font-family">{user.prenom} {user.nom}</strong>
                                    <small>{user.is_admin === 2 ? 'super-admin' : user.is_admin === 1 ? 'admin' : ''}</small>
                                </a>
                                <div className="dropdown-divider"></div>
                                <Link to='/settings' className="dropdown-item">Paramètres comptes</Link>
                                <div className="dropdown-divider"></div>
                                <a href="" onClick={this.logout} className="dropdown-item">Me Déconnecter</a>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Navbar;