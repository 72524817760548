import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {url, subUrl} from '../../config';
import './Tomes.css';
import Sidebar from '../../Components/sidebar/Sidebar';

class Tomes extends Component {
    constructor () {
        super();
        this.state = {
            chronique: {},
            isChroniqueNotExits: false,
            tomes: [],
            isNoTomeExist: false,
            isParamExists: false,
            isLoading: true,
        };
    }

    activeTab = (e) => {
        
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        const {chronique_id} = this.props.match.params;

        if(token) {
            axios.get(`${url}/api/office/chroniques/${chronique_id}?token=${token}`)
            .then(res => {
                const {data} = res;
                const {status} = data;
                if(status === 'error') {
                    this.setState({
                        isChroniqueNotExits: true,
                        isLoading: false
                    });
                } else {
                    this.getTomes(data.chronique, token);
                    this.setState({isLoading: false, chronique: data.chronique})
                }
            });
        } else {
            this.props.history.goBack();
        }
    }

    getTomes = (chronique, token) => {
        axios.get(`${url}/api/office/tomes/${chronique.id}?token=${token}`)
        .then(res => {
            res.data.count > 0 
                ? this.setState({ tomes: res.data.tomes }) 
                : this.setState({ isNoTomeExist: true })
        });
    }

    onDelete = (id) => {
        const token = sessionStorage.getItem('token');
        this.setState({isLoading: true});

        axios.get(`${url}/api/office/tomes/${id}/remove?token=${token}`)
        .then(res => {
            let tomes = this.state.tomes.filter(tome => tome.id !== id);
            this.setState({tomes, isLoading: false});
        });
    }

    renderNewTomeAlerte = () => {
        return (
            <div className="alert alert-info">
                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                    Aucun Tome n'est encore ajouté &nbsp; &nbsp; &nbsp; &nbsp;
                    <Link  to={`/new-tome/${this.state.chronique.id}`} className="btn btn-sm btn-primary">Ajouter un Tome</Link>
                </p>
            </div>
        )
    }

    renderTable = () => {
        const {tomes, chronique} = this.state;
        // let imgUrl = `${url}/img`;
        let imgUrl = `${url}/public/img`;

        return (
            <>
                <table className="table table-striped card-text">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Numéro</th>
                            <th>Première page</th>
                            <th>Publication</th>
                            <th>Formmule</th>
                            <th>Prix</th>
                            <th>Quantité vendus</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tomes.map((tome, index) => (
                            <tr key={index}>
                                <td className="text-justify">{ tome.description }</td>
                                <td>{ tome.numero }</td>
                                <td><img className="tome-page-list-view" src={`${imgUrl}/${tome.premiere_page}`} alt="premiere page Tome" /></td>
                                <td>{ tome.publication }</td>
                                <td>{ tome.formule === 'gratuit' ? 'Gratuit' : 'Payant' }</td>
                                <td>{ tome.prix }</td>
                                <td>{ tome.quantite_vendu }</td>
                                <td>
                                    <Link to={`/edit-tome/${tome.id}`} className="btn btn-sm btn-warning mr-2"><i className="fas fa-edit"></i></Link>
                                    <button className="btn btn-sm btn-danger" onClick={() => this.onDelete(tome.id)}><i className="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        )
    }
    
    render() {
        const {isChroniqueNotExits, chronique, isNoTomeExist, isLoading} = this.state;
        
        return (
            <>
                <Sidebar activeTab={this.activeTab()} />
                <div className="col-md-9 px-0 py-4">
                    <div id="list-chroniques" className="row mr-4">
                        <div className="col-md-12">
                            <div className="clearfix mb-md-3">
                            <h2 className="float-left">{ isChroniqueNotExits ? chronique.titre : ''}</h2>
                                <Link to={`/new-tome/${chronique.id}`} className="btn btn-md btn-primary float-right">Nouveau Tome</Link>
                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <div className="card">
                                {
                                    isChroniqueNotExits
                                    ?   <div className="card-body">
                                            <div className="alert alert-warning">
                                                <p style={{ marginBottom: '0px', textAlign: 'center' }}>
                                                    La chronique sélectonnée est introuvable
                                                </p>
                                            </div>
                                        </div>
                                    :
                                        <div className="card-body">
                                            {    isLoading ?
                                                    <div className="d-flex justify-content-center">
                                                        <strong>Loading...</strong>
                                                    </div>
                                                :   isNoTomeExist ? this.renderNewTomeAlerte() : this.renderTable() 
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Tomes;